// Entry point for the build script in your package.json
import 'flowbite/dist/flowbite.turbo.js';
import '@hotwired/turbo-rails'
import './controllers'

import 'trix'
import '@rails/actiontext'

document.addEventListener("turbo:morph-element", () => {
  window.initFlowbite()
})
